import React from "react"
import {graphql} from "gatsby"
import {MDXRenderer} from "gatsby-plugin-mdx";
import {PostHead} from "../components/PostHead";
import SiteLayout from "../components/SiteLayout";

export default function Template({data}) {
  const post = data.mdx
  const {frontmatter, body} = post
  return (
    <SiteLayout>
      <article>
        <PostHead frontmatter={frontmatter}/>

        <div className={`prose lg:prose-xl max-w-none`}>
          <MDXRenderer>{body}</MDXRenderer>
        </div>
      </article>
    </SiteLayout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date
        slug
        title
        description
        tags
      }
    }
  }
`
